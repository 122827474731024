.educational-process {
  position: relative;
  padding: 70px 0;
  &__items {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    margin-bottom: 70px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item {
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #58998b;
    margin: 10px;
    color: #fff;
    font-size: 20px;
    transition: 0.3s;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
    &:hover {
      transition: 0.3s;
      background-color: #325d54;
    }
  }
}

.modalWindow {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 20px;
  top: 0;
  left: 0;
  background: #00000066;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    min-width: 350px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
  }
  &__close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: #000;
      border-radius: 2px;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 10px;
  }
  &__item {
    padding: 5px;
  }
  &__link {
    font-size: 18px;
    color: #58998b;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      transition: 0.3s;
      color: #325d54;
      text-decoration: underline;
    }
  }
}

@media (max-width: 900px) {
  .educational-process {
    &__items {
      grid-template-columns: repeat(3, 33.33%);
    }
  }
}
@media (max-width: 724px) {
  .educational-process {
    &__items {
      grid-template-columns: repeat(2, 50%);
    }
  }
}
@media (max-width: 450px) {
  .educational-process {
    &__items {
      grid-template-columns: repeat(1, 100%);
    }
  }
}
