.study-table {
  .tabeel {
    border-bottom: 1px solid #000;
  }
  width: 880px;
  margin: 30px auto 70px;
  &__col {
    display: grid;
    grid-template-columns: 80px 550px 150px 50px;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
  }
  &__head {
    background-color: #badfff;
  }
  &__content {
    &:nth-child(odd) {
      background-color: #d8fff9;
    }
  }
  &__body {
    &:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
  }
  &__secondCol {
    padding: 20px 20px;
    background-color: #fbfbfb;
    flex-direction: column;
    gap: 10px;
    display: none;
    position: relative;
    &::before {
      height: 85%;
      width: 2px;
      content: "";
      background-color: #58998b;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
    li {
      span {
        margin-left: 10px;
      }
    }
  }
  &__secondCol.active {
    display: flex;
  }
}

@media (max-width: 940px) {
  .study-table {
    width: 100%;
    &__content,
    &__head {
      grid-template-columns: 10% 60% 20% 10%;
    }
  }
}
@media (max-width: 600px) {
  .study-table {
    &__content,
    &__head {
      grid-template-columns: 50px 60% 70px 10px;
    }
  }
}

@media (max-width: 450px) {
  .study-table {
    .form {
      display: none;
    }
    &__content,
    &__head {
      grid-template-columns: 50px 70% 10px;
    }
  }
}
