.login {
  height: 100vh;
  &__title {
    margin-bottom: 20px;
    text-align: center;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
  }
  &__form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 10px;
    }

    input[type="text"],
    input[type="password"] {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 3px;
    }

    button {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      color: #fff;
      background-color: #58998b;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #325d54;
      }
    }
  }
}
.error {
  color: red;
}
