.structure-page {
  padding: 70px 0;
  &__cards {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    margin-bottom: 70px;
  }
  &__card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #58998b;
    color: #fff;
    margin: 10px 10px;
    height: 150px;
    padding: 0px 10px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    &:hover {
      background-color: #325d54;
      transition: 0.3s;
    }
  }
  &__card-link {
    position: absolute;
    width: 100%;
    height: 100px;
  }
  &__items {
    margin-bottom: 70px;
    li {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  &__link {
    color: #58998b;
  }
  &__img {
    width: 80%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
}
@media (max-width: 724px) {
  .structure-page {
    &__cards {
      grid-template-columns: repeat(2, 50%);
    }
  }
}
@media (max-width: 450px) {
  .structure-page {
    &__cards {
      grid-template-columns: repeat(1, 100%);
    }
  }
}
