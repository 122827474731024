.applicant {
  padding: 70px 0;
  font-size: 18px;
  &__title {
    text-align: center;
    margin-bottom: 20px;
  }
  &__text {
    margin-bottom: 15px;
  }
  .title-2 {
    margin-bottom: 30px;
  }
  &__date {
    padding-left: 20px;
    margin-bottom: 25px;
  }
}

.benefits {
  padding: 70px 0;
  &__item {
    margin-bottom: 20px;
    padding-left: 30px;
    position: relative;
    &::before {
      width: 10px;
      height: 10px;
      position: absolute;
      content: "";
      border-radius: 50px;
      background-color: #58998b;
      top: 5px;
      left: 0;
    }
  }
  &__item-title {
    margin-bottom: 10px;
    font-size: 22px;
    color: #58998b;
  }
}

.contacts {
  &__text {
    margin-bottom: 20px;
  }
  &__link {
    color: #58998b;
  }
}
