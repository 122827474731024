.footer {
  padding: 30px 0;
  background-color: #1d1d1d;
  color: #a9a9a9;
  &__text {
    text-align: center;
    font-size: 12px;
  }
  &__contacts {
    margin-bottom: 20px;
  }
}
