.news-page {
  padding: 70px 0;
  .container {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .small-title {
    font-size: 18px;
  }
  &__content {
    padding-right: 30px;
  }
  &__img-block {
    width: 100%;
    margin-bottom: 10px;
    img {
      width: 100%;
    }
  }
  &__title.title {
    font-size: 36px;
  }
  &__text {
    margin-bottom: 20px;
    text-indent: 20px;
    font-size: 18px;
    line-height: 20px;
  }
  &__date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    color: #999999;
  }
  &__link {
    color: #58998b;
    transition: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &:hover {
      color: #325d54;
      transition: 0.3;
    }
  }
}

@media (max-width: 724px) {
  .news-page {
    .container {
      grid-template-columns: repeat(1, 100%);
    }
    &__content {
      padding-right: 0;
    }
    .small-news-card {
      flex-direction: column;
      padding: 0 10px;
      display: none;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: block;
      }
    }
    &__cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
@media (max-width: 500px) {
  .news-page {
    .small-news-card {
      &:nth-child(3) {
        display: none;
      }
    }
  }
}
