.about {
  padding: 70px 0;
  .title-2 {
    margin-top: 70px;
  }
  &__box {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: center;
  }
  &__img {
    width: 90%;
    img {
      width: 100%;
    }
  }

  &__text {
    font-size: 18px;
    text-indent: 20px;
    margin-bottom: 20px;
    span {
      color: #58998b;
      font-weight: 700;
    }
  }
}

@media (max-width: 928px) {
  .about {
    .title {
      &:first-child {
        text-align: center;
        display: block;
        width: max-content;
        margin: 0 auto 50px;
      }
    }
    &__box {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
@media (max-width: 600px) {
  .about {
    .title {
      &:first-child {
        text-align: start;
        width: auto;
      }
    }
  }
}
