.small-news-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  &__title {
    color: #58998b;
    margin-bottom: 15px;
    display: block;
    transition: 0.3s;

    &:hover {
      color: #325d54;
      transition: 0.3s;
    }
  }
  &__img {
    max-width: 200px;
    img {
      width: 100%;
    }
  }
  &__date {
    color: #999999;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
